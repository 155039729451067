<template>
    <div class="login">
        <main class="main">
            <ul class="title">
                <div class="logo">
                    <img src="../assets/logo.png" width="100%" height="100%" />
                </div>
                <li>
                    <h1>梅州市青少年心理健康大数据智慧管理中心</h1>
                    <!--<div class="line"></div>-->
                    <h2>
                        助力心理健康大数据提质增效 推动青少年心理健康发展
                        <!--<div class="QR_code">
                            <img src="../assets/QR_code.jpeg" width="100%" />
                            <span>APP下载</span>
                        </div>-->
                    </h2>
                </li>
            </ul>
            <div style="min-width:30%;">
                <div class="content">
                    <div class="outBg">
                        <div class="innerBg">
                            <h3>用户登录</h3>
                            <el-form ref="form" status-icon :model="form" :rules="rules">
                                <el-form-item prop="username">
                                    <el-input v-model="form.username" placeholder="请输入登录账户">
                                        <i class="iconfont" slot="prefix">&#xe61f;</i>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="password">
                                    <el-input type="password" v-model="form.password" placeholder="请输入密码">
                                        <i class="iconfont" slot="prefix">&#xe628;</i>
                                    </el-input>
                                </el-form-item>
                                <el-form-item v-if="showCode">
                                    <el-input
                                        v-model="form.vcode"
                                        placeholder="请输入验证码"
                                        style="width:53%;margin-right:8%;vertical-align: bottom;"
                                        @keydown.enter.native="submitForm('form')"
                                    >
                                        <i class="iconfont" slot="prefix">&#xe6bf;</i>
                                    </el-input>
                                    <el-button type="primary" style="width:39%;font-size:0.12rem;" @click="getCode">
                                        {{ codeText }}
                                    </el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" style="width:100%;" @click="submitForm('form')">
                                        登录
                                    </el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <a href="https://beian.miit.gov.cn/" target="blank" style="color:rgba(44, 160, 249, 0.5);" class="beian">
            粤ICP备2022061981号-1
        </a>
    </div>
</template>
<script>
    export default {
        mounted() {
            localStorage.removeItem('token');
            localStorage.removeItem('userName');
            localStorage.removeItem('district_id');
            localStorage.removeItem('userid');
            //const { countyName } = this.$route.query;
            //this.countyName = countyName;
            //!countyName && localStorage.removeItem('vuex');
        },
        data() {
            return {
                codeNum: 60,
                codeText: '发送验证码',
                form: {
                    username: '',
                    password: '',
                    vcode: ''
                },
                rules: {
                    username: [
                        { required: true, message: '请输入用户名', trigger: 'blur' }
                        //{ min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
                    ],
                    password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
                },
                showCode: false,
                countyName: ''
            };
        },
        methods: {
            getCode() {
                this.form.vcode = '';
                if (this.codeNum == 60) {
                    this.$http
                        .getCode({ username: this.form.username })
                        .then(res => {
                            const time = setInterval(() => {
                                this.codeNum--;
                                this.codeText = `重新发送(${this.codeNum}s)`;
                                if (this.codeNum === 0) {
                                    clearInterval(time);
                                    this.codeNum = 60;
                                    this.codeText = `发送验证码`;
                                }
                            }, 1000);
                            this.$toast.center('验证码发送成功');
                        })
                        .catch(err => this.$toast.center(err.msg));
                } else {
                    uni.showToast({
                        icon: 'none',
                        title: `请${this.codeNum}s后在尝试`
                    });
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        this.$http
                            .login(this.form)
                            .then(res => {
                                this.$toast.center('登录成功');
                                localStorage.setItem('token', res.token);
                                localStorage.setItem('userName', res.username);
                                localStorage.setItem('district_id', res.district_id);
                                localStorage.setItem('userid', res.userid);
                                this.$router.push({ path: '/home' });
                                this.showCode = false;
                            })
                            .catch(rej => {
                                console.log(rej);
                                if (rej.code === 10002) {
                                    this.showCode = true;
                                }
                                this.$toast.center(rej.msg);
                            });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    };
</script>
<style lang="less">
    .login {
        background: url('../assets/bg.png') no-repeat;
        background-size: cover;
        height: 100vh;
        display: flex;
        align-items: center;
        .main {
            width: 100%;
            display: flex;
            align-items: center;
            .logo {
                position: absolute;
                top: -40%;
                left: 10%;
                width: 4rem;
            }
            ul.title {
                min-width: 55%;
                height: 380/100rem;
                padding-left: 100/100rem;
                box-sizing: border-box;
                background: linear-gradient(90deg, #030a21 0%, rgba(5, 15, 45, 0) 100%);
                color: #4d93ff;
                display: flex;
                align-items: center;
                position: relative;
                > li {
                    height: 146/100rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                h1 {
                    font-size: 46/100rem;
                    font-weight: 600;
                    line-height: initial;
                }
                .line {
                    width: 60/100rem;
                    height: 8/100rem;
                    background: #4d93ff;
                }
                h2 {
                    font-size: 34/100rem;
                    line-height: initial;
                    position: relative;
                }
                .QR_code {
                    width: 100/100rem;
                    height: 100/100rem;
                    border: 1px solid #fff;
                    text-align: center;
                    position: absolute;
                    right: 80/100rem;
                    top: -50%;
                    > span {
                        font-size: 12/100rem;
                        margin-top: 10/100rem;
                    }
                }
            }
            .content {
                width: 646/100rem;
                height: 500/100rem;
                background-size: cover;
                //background: url('../assets/pic_border.png');
                background-size: cover;
                display: flex;
                align-items: center;
                justify-content: center;
                .iconfont {
                    font-size: 22/100rem;
                    line-height: 50/100rem;
                }
                .el-input__inner {
                    background: transparent;
                    color: #fff;
                    height: 50/100rem;
                    line-height: 50/100rem;
                    font-size: 18/100rem;
                }
                .el-button--primary {
                    height: 50/100rem;
                    font-size: 18/100rem;
                }
            }
            .outBg {
                width: 586/100rem;
                height: 440/100rem;
                background: #030b25;
                box-shadow: 0/100rem 3/100rem 15/100rem 0/100rem rgb(47 62 109);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4/100rem;
                border-radius: 10/100rem;
            }
            .innerBg {
                width: 386/100rem;
                box-sizing: border-box;
                text-align: center;
                h3 {
                    font-size: 24/100rem;
                    margin-bottom: 30/100rem;
                    color: #fff;
                }
            }
        }

        .beian {
            position: fixed;
            bottom: 20/100rem;
            font-size: 18/100rem;
            left: 50%;
            transform: translateX(-50%);
        }
    }
</style>
